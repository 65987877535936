import { Component, forwardRef, HostBinding, inject, Input, output } from '@angular/core';
import { NG_VALUE_ACCESSOR, type ControlValueAccessor } from '@angular/forms';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { PremiumFeatureModalComponent } from '@core/modals/premium-feature-modal/premium-feature-modal.component';
import { type Company, type ConnectionStatus } from '@core/models/company';
import { ModalService } from '@core/services/modal.service';
import { CompanySelectorModalComponent } from '../../modals/company-selector-modal/company-selector-modal.component';

@Component({
  selector: 'company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanySelectorComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [FocusableDirective, AssetSrcDirective],
})
export class CompanySelectorComponent implements ControlValueAccessor {
  private readonly modalService = inject(ModalService);
  @Input() placeholder: string;
  @Input() companyId: number;
  @Input() modalTitle: string;
  @Input() customOpenFunc: (open: () => {}) => void;
  @Input() showPremiumFeatureModal = false;
  @Input() connectionStatusIds: ConnectionStatus[] = [];
  @Input() nonConnectedCompanyAction: 'default' | 'connect' = 'default';

  valueChanged = output();

  @HostBinding('attr.disabled')
  public isDisabled: boolean;
  private _value: Company;

  public set value(val: Company) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
    this.valueChanged.emit();
  }

  public get value(): Company {
    return this._value;
  }

  private onChange = (val: Company) => {};
  private onTouched = () => {};

  public openSelector(): void {
    if (this.showPremiumFeatureModal) {
      this.modalService.open({
        content: PremiumFeatureModalComponent,
      });
      return;
    }

    if (this.isDisabled) {
      return;
    }

    if (this.customOpenFunc) {
      this.customOpenFunc(this.openModal.bind(this));
      return;
    }

    this.openModal();
  }

  private openModal(): void {
    this.modalService
      .open({
        content: CompanySelectorModalComponent,
        inputs: {
          title: this.modalTitle,
          connectionStatusIds: this.connectionStatusIds,
          nonConnectedCompanyAction: this.nonConnectedCompanyAction,
        },
        options: {
          windowClass: 'modal-background-transparent, modal-content-overflow-visible',
        },
      })
      .result.then((res: any) => (this.value = res))
      .catch(() => {});
  }

  public writeValue(value: Company): void {
    this._value = value;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
