import { computed, DestroyRef, inject, Injectable, signal, type Signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, UrlSerializer } from '@angular/router';
import { HttpService } from '@clover/core/services/http.service';
import { map, take, tap, type Observable } from 'rxjs';

enum WorkspaceCounterType {
  Inbox = 'Inbox',
}

interface WorkspaceUnreadConversationsCountResponse {
  workspaceId: number;
  unreadCount: number;
}

export interface WorkspaceUnreadConversationsCount {
  workspaceId: number;
  unreadConversationsCount: number;
}

@Injectable({
  providedIn: 'root',
})
export class UnreadCountersService {
  private readonly _counts = signal<WorkspaceUnreadConversationsCount[]>([]);
  private readonly _totalUnreadCount = computed(() => {
    return this._counts().reduce((acc, { unreadConversationsCount }) => acc + unreadConversationsCount, 0);
  });

  private readonly http = inject(HttpService);
  private readonly router = inject(Router);
  private readonly serializer = inject(UrlSerializer);
  private readonly destroyRef = inject(DestroyRef);

  get totalUnreadCount(): Signal<number> {
    return this._totalUnreadCount;
  }

  constructor() {
    this.loadUnreadCounters();
  }

  getWorkspaceUnreadCount(workspaceId: number): Signal<number> {
    return computed(() => {
      return this._counts().find(({ workspaceId: id }) => id === workspaceId)?.unreadConversationsCount ?? 0;
    });
  }

  getWorkspacesUnreadCount(workspaceIds: number[]): Signal<number> {
    return computed(() => {
      return this._counts().reduce((acc, { workspaceId, unreadConversationsCount }) => {
        return workspaceIds.includes(workspaceId) ? acc + unreadConversationsCount : acc;
      }, 0);
    });
  }

  updateWorkspaceUnreadCounters(workspaceId: number): void {
    this.getWorkspaceCounters(workspaceId)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        take(1),
        tap((workspaceCounters) => {
          this._counts.update((counts) => {
            return counts.map((count) => {
              if (count.workspaceId === workspaceId) return workspaceCounters;
              return count;
            });
          });
        }),
        map(() => undefined),
      )
      .subscribe();
  }

  private loadUnreadCounters(): void {
    this.getWorkspacesCounters()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        take(1),
        tap((workspacesCounters) => {
          this._counts.set(workspacesCounters);
        }),
        map(() => undefined),
      )
      .subscribe();
  }

  private getWorkspacesCounters(
    workspaceIds: number[] = [],
    type: WorkspaceCounterType = WorkspaceCounterType.Inbox,
  ): Observable<WorkspaceUnreadConversationsCount[]> {
    const mapWorkspaceCounter = (c: WorkspaceUnreadConversationsCountResponse): WorkspaceUnreadConversationsCount => ({
      workspaceId: c.workspaceId,
      unreadConversationsCount: c.unreadCount ?? 0,
    });

    const countersUrlTree = this.router.createUrlTree(['api', 'stream-conversations', 'counters'], {
      queryParams: {
        type,
        workspacesIds: workspaceIds.length ? workspaceIds : undefined,
      },
    });
    const countersPath = this.serializer.serialize(countersUrlTree);

    return this.http
      .getV2<WorkspaceUnreadConversationsCountResponse[]>(countersPath)
      .pipe(map((counters) => counters.map(mapWorkspaceCounter)));
  }

  private getWorkspaceCounters(
    workspaceId: number,
    type: WorkspaceCounterType = WorkspaceCounterType.Inbox,
  ): Observable<WorkspaceUnreadConversationsCount> {
    return this.getWorkspacesCounters([workspaceId], type).pipe(map((counters) => counters[0]));
  }
}
