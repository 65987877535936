import { Injectable, inject } from '@angular/core';
import * as Doka from '../../../assets/doka/doka.min';
import { HttpService } from './http.service';

const MaxFileName = 255;
@Injectable({
  providedIn: 'root',
})
export class DokaService {
  private readonly httpService = inject(HttpService);
  private readonly doka: Doka.DokaInstance;

  constructor() {
    this.doka = Doka.create({
      outputData: true,
      utils: ['crop', 'filter', 'resize'],
      outputType: 'image/jpeg',
      outputWidth: 256,
      outputHeight: 256,
      cropAspectRatio: '1:1',
      outputCanvasBackgroundColor: '#fff',
      cropLimitToImageBounds: false,
    });
  }

  public async openDoka(
    image: string,
    dokaConfig?: Doka.DokaData,
    filename?: string,
  ): Promise<{ file: File; base64: string; dokaConfig: Doka.DokaData }> {
    return await new Promise((resolve, reject) => {
      this.doka.edit(image, dokaConfig).then((res) => {
        if (!res) {
          reject(null);
          return;
        }

        // BUGFIX #6387
        // dirtyhack: the image-uploader should be rewrite to be similar on company/profile details page
        if (filename) {
          res.file.name = filename;
        }

        const reader = new FileReader();
        reader.readAsDataURL(res.file);
        reader.onloadend = () => {
          resolve({
            file: res.file,
            base64: reader.result as string,
            dokaConfig: res.data,
          });
        };
      });
    });
  }

  public async uploadImage(file: File): Promise<{ fileName: string }> {
    const formData = new FormData();
    formData.append('filepond', file, file.name);

    return await this.httpService.post(`api/tempFiles`, formData);
  }
}
