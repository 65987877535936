import { Component, Input, inject } from '@angular/core';
import { CompanyListsService } from '../../company-lists.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { type Company, type CompanySearchModel } from '@core/models/company';
import { ToastrService } from '@core/services/toastr.service';
import { CompanyList } from '../../models/company-list';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, finalize, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { CompanyInfoComponent } from '../../../core/components/company-info/company-info.component';
import { LoaderComponent } from '../../../core/components/loader/loader.component';
import { FormsModule } from '@angular/forms';
import { CompanySearchComponent } from '../../components/company-search/company-search.component';
import { NgClass } from '@angular/common';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

@UntilDestroy()
@Component({
  selector: 'app-add-company-to-list-modal',
  templateUrl: './add-company-to-list-modal.component.html',
  styleUrls: ['./add-company-to-list-modal.component.scss'],
  standalone: true,
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    NgClass,
    CompanySearchComponent,
    FormsModule,
    LoaderComponent,
    CompanyInfoComponent,
    ButtonLoadingDirective,
    TranslateModule,
  ],
})
export class AddCompanyToListModalComponent {
  private readonly companyListsService = inject(CompanyListsService);
  public readonly activeModal = inject(NgbActiveModal);
  private readonly toastr = inject(ToastrService);
  @Input() list: CompanyList;

  public isLoading = false;
  public isCompanyAdded = false;

  public searchValue: string;
  public selectedCompany: Company;
  public matchedCompanies: Company[] = [];

  public clearSearch(): void {
    this.selectedCompany = null;
    this.searchValue = '';
  }

  public onTypeaheadSelect(company: CompanySearchModel): void {
    if (!company) {
      return;
    }

    this.isLoading = true;
    this.companyListsService
      .getCompanyDetails(company.id)
      .pipe(
        untilDestroyed(this),
        map((res) => {
          this.selectedCompany = res;
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }

  public confirmSelection(): void {
    this.companyListsService
      .addCompaniesToList(this.list.id, [this.selectedCompany.id])
      .pipe(
        untilDestroyed(this),
        map(() => {
          this.isCompanyAdded = true;
        }),
        catchError((err) => {
          if (err?.error?.errors[0]?.errorCode === 'company_already_added') {
            this.toastr.error('companyLists.errors.companyExistsInList', {
              companyName: this.selectedCompany.name,
              listName: this.list.name,
            });
          } else {
            this.toastr.displayServerErrors(err);
          }
          return of(err);
        }),
      )
      .subscribe();
  }

  public addAnother(): void {
    this.clearSearch();
    this.isCompanyAdded = false;
  }
}
