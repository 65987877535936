import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NzProgressComponent } from 'ng-zorro-antd/progress';

import { LoaderComponent } from '@core/components/loader/loader.component';
import { ScrollableAreaComponent } from '@core/components/scrollable-area/scrollable-area.component';
import { UserLogoComponent } from '@core/components/user-logo/user-logo.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { percentageFormatter } from '@core/helpers/nz-progress-formatters';
import { DateFormatPipe, DateFormatRelativePipe } from '@core/pipes/date-format.pipe';
import { StringHelpersService } from '@core/services/string-helpers.service';
import { ToastrService } from '@core/services/toastr.service';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';

import { TaskSearchModel, TaskStatus } from '../../tasks/models/task';
import { type TaskHistoryEvent, TaskHistoryEventType } from '../models/task-history-event';
import { TaskHistoryIconType } from '../models/task-history-icon';
import { TasksHistoryService } from '../tasks-history.service';

const TASK_LOAD_LIMIT = 20;

@Component({
  selector: 'app-task-history-modal',
  templateUrl: './task-history-modal.component.html',
  styleUrls: ['./task-history-modal.component.scss'],
  standalone: true,
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    NzProgressComponent,
    ButtonComponent,
    LoaderComponent,
    ScrollableAreaComponent,
    UserLogoComponent,
    TranslateModule,
    DateFormatRelativePipe,
    DateFormatPipe,
  ],
})
export class TaskHistoryModalComponent {
  private readonly tasksHistoryService = inject(TasksHistoryService);
  private readonly toastr = inject(ToastrService);
  public readonly stringHelpers = inject(StringHelpersService);
  public readonly activeModal = inject(NgbActiveModal);
  @Input() task: TaskSearchModel;

  public isButtonLoading = false;
  public isLoading = true;
  public history: TaskHistoryEvent[] = [];
  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly percentageFormatter = percentageFormatter;
  private isLazyLoading = false;
  private currentOffset = 0;
  private totalTasks: number;
  private readonly progressColors = {
    bad: '#F22206',
    medium: '#F2994A',
    good: '#27AE60',
  };

  public get iconTypes() {
    return TaskHistoryIconType;
  }

  public ngOnInit(): void {
    this.loadTasks();
  }

  public onScrollDown(): void {
    if (!this.isLazyLoading && this.totalTasks > this.history.length) {
      this.currentOffset = this.history.length;
      this.loadTasks();
    }
  }

  public getAssignedToString(event: TaskHistoryEvent): string {
    if (this.isTaskAssignedToOneUser(event)) {
      const user = event?.assignedTo?.users[0];
      if (!user) {
        return 'Unknown User';
      }

      let userString = `${user.firstName} ${user.lastName}`;

      if (user.title) {
        userString += ` - ${user.title}`;
      }

      return userString;
    } else {
      return [
        ...event.assignedTo.communicationRoles.map((role) => role.title),
        ...event.assignedTo.users.map((user) => `${user.firstName} ${user.lastName}`),
      ].join(', ');
    }
  }

  public isTaskAssignedToOneUser(event: TaskHistoryEvent): boolean {
    return event?.assignedTo?.communicationRoles?.length === 0 && event?.assignedTo?.users?.length === 1;
  }

  public getTimelineIcon(event: TaskHistoryEvent): TaskHistoryIconType {
    if (event.type === TaskHistoryEventType.Assigned || event.type === TaskHistoryEventType.Reassigned) {
      if (this.isTaskAssignedToOneUser(event)) {
        return TaskHistoryIconType.UserLogo;
      }

      return TaskHistoryIconType.StepCompleted;
    }

    if (
      event.type === TaskHistoryEventType.TaskAccepted ||
      event.type === TaskHistoryEventType.TaskStarted ||
      event.type === TaskHistoryEventType.TaskClosed ||
      event.type === TaskHistoryEventType.TaskCompleted ||
      event.type === TaskHistoryEventType.ProductTaskCopied
    ) {
      return event.performer ? TaskHistoryIconType.UserLogo : TaskHistoryIconType.StepCompleted;
    }

    if (event.type === TaskHistoryEventType.StepSubmitted) {
      return TaskHistoryIconType.StepCompleted;
    }

    if (event.type === TaskHistoryEventType.StepStarted || event.type === TaskHistoryEventType.StepRestarted) {
      return TaskHistoryIconType.StepStarted;
    }

    return TaskHistoryIconType.None;
  }

  public color(percent: number): string {
    if (percent > 70) {
      return this.progressColors.good;
    }

    if (percent > 35) {
      return this.progressColors.medium;
    }

    return this.progressColors.bad;
  }

  openTaskResults(): void {
    this.isButtonLoading = true;
    this.tasksHistoryService.generateTaskLink(this.task.id).subscribe((res) => {
      const token = res.token;
      this.isButtonLoading = false;
      window.open(`/task-info/${this.task.id}?token=${token}`, '_blank');
    });
  }

  disableTaskResult(taskStatus: TaskStatus) {
    if (this.task.assignedByCompanyProduct) return true;
    return taskStatus !== TaskStatus.Completed;
  }

  private loadTasks(): void {
    this.isLazyLoading = true;
    this.tasksHistoryService
      .getTaskHistory(this.task.id, TASK_LOAD_LIMIT, this.currentOffset)
      .then((res) => {
        this.history = [...this.history, ...res.data];
        this.totalTasks = res.total;
        this.isLoading = false;
        this.isLazyLoading = false;
      })
      .catch((error) => {
        this.toastr.displayServerErrors(error);
      });
  }
}
