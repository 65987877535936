import { Component, type OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from '@core/services/toastr.service';
import { type ServerError } from '@core/models/serverError';
import { CompanyListsService } from '../../company-lists.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, finalize, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { NgClass } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'app-create-list-modal',
  templateUrl: './create-list-modal.component.html',
  styleUrls: ['./create-list-modal.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    FormErrorMessagesComponent,
    ButtonLoadingDirective,
    TranslateModule,
  ],
})
export class CreateListModalComponent implements OnInit {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly companyListsService = inject(CompanyListsService);
  private readonly toastr = inject(ToastrService);
  public isLoading = false;
  public form: UntypedFormGroup;

  public customErrors = {
    entity_already_exists: 'companyLists.errors.listNameExists',
  };

  public ngOnInit(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
    });
  }

  public createList(): void {
    this.isLoading = true;
    this.companyListsService
      .createList(this.form.value.name)
      .pipe(
        untilDestroyed(this),
        map((res) => {
          this.activeModal.close(res);
        }),
        catchError((err) => {
          if (!err.error?.errors) {
            this.toastr.error('common.errorMessages.unexpected');
            return;
          }
          const field = this.form.get('name');
          err.error.errors.forEach((err: ServerError) => {
            field.setErrors({
              ...field.errors,
              [err.errorCode]: err.errorMessage,
            });
          });
          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }
}
