import { Component, Input } from '@angular/core';
import { Company } from '@core/models/company';
import { type Address } from '../../models/address';
import { CompanyLogoComponent } from '@core/components/company-logo/company-logo.component';

import { TranslateModule } from '@ngx-translate/core';
import { XMoreComponent } from '@core/components/x-more/x-more.component';
import { WebsiteUrlPipe } from '@core/pipes/website-url.pipe';

@Component({
  selector: 'company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss'],
  standalone: true,
  imports: [CompanyLogoComponent, TranslateModule, XMoreComponent, WebsiteUrlPipe],
})
export class CompanyInfoComponent {
  @Input() company: Company;
  @Input() showLogo = true;
  @Input() sectorsMaxLength = 2;
  @Input() categoriesMaxLength = 3;
  @Input() canExpand = false;
  @Input() compactMode = false;

  public get address(): Address {
    return this.company?.publicData?.address || this.company?.address;
  }
}
