import {
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  inject,
  Input,
  type OnInit,
  TemplateRef,
  ViewEncapsulation,
  output,
} from '@angular/core';
import { type ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, type Observable, of, switchMap, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ToastrService } from '@core/services/toastr.service';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TranslateModule } from '@ngx-translate/core';
import { NgTemplateOutlet } from '@angular/common';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { FocusableDirective } from '@core/directives/focusable.directive';

const FETCH_LIMIT = 25;

@UntilDestroy()
@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InfiniteScrollModule,
    TranslateModule,
    NgTemplateOutlet,
    ButtonComponent,
    FocusableDirective,
  ],
})
export class SearchInputComponent implements OnInit, ControlValueAccessor {
  @Input()
  searchKeyword = 'name';

  @Input()
  itemTemplate: TemplateRef<unknown>;

  @Input()
  placeholder: string;

  @Input()
  searchFn: (query: string, limit: number, offset: number) => Observable<{ data: unknown[]; total: number }>;

  @Input()
  nameFormatFunc: (item: unknown) => string;

  @Input()
  preselectedItem: unknown | null = null;

  @Input()
  preloadedMatches: unknown[] = [];

  selectionChanged = output<unknown>();

  matchedItemsChanged = output<unknown[]>();

  searching = false;

  searchQueryFormControl = new FormControl<string>('');

  matchedItems: unknown[] = [];
  totalResults = 0;
  selectedItem: unknown;

  suggestionsDropdownVisible = false;

  loadNextPage$ = new BehaviorSubject(null);
  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  private readonly toastrService = inject(ToastrService);
  private readonly elementRef = inject(ElementRef);

  get canClear(): boolean {
    return !!this.searchQueryFormControl.value && !this.searching;
  }

  get noResults(): boolean {
    return this.searchQueryFormControl.value && !this.searching && this.matchedItems.length === 0;
  }

  onChange(_: any): void {}

  onTouched(): void {}

  writeValue(value: any): void {
    this.searchQueryFormControl.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.searchQueryFormControl.disable();
    } else {
      this.searchQueryFormControl.enable();
    }
  }

  ngOnInit(): void {
    if (this.preselectedItem) this.selectItem(this.preselectedItem);
    this.matchedItems = this.preloadedMatches || [];

    this.selectionChanged.emit(this.selectedItem);
    this.matchedItemsChanged.emit(this.matchedItems);

    this.initializeQueryChangeListener();
  }

  selectItem(item: unknown): void {
    this.selectedItem = item;
    this.selectionChanged.emit(item);
    this.suggestionsDropdownVisible = false;

    const formattedName = this.nameFormatFunc ? this.nameFormatFunc(item) : item[this.searchKeyword];
    this.searchQueryFormControl.setValue(formattedName, { emitEvent: false });
  }

  handleClear(): void {
    this.searchQueryFormControl.setValue('');
    this.clearSelection();
  }

  clearSelection(): void {
    this.selectedItem = null;
    this.selectionChanged.emit(null);
    this.matchedItemsChanged.emit([]);
  }

  @HostListener('focusin')
  onFocus(): void {
    this.suggestionsDropdownVisible = true;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.suggestionsDropdownVisible = false;
    }
  }

  private initializeQueryChangeListener(): void {
    this.searchQueryFormControl.valueChanges
      .pipe(
        untilDestroyed(this),
        map((query) => (query ? query.trim() : '')),
        tap((query) => {
          this.clearSelection();
          this.onChange(query);
          this.onTouched();
        }),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.searching = true)),
        switchMap((query) => {
          if (!query) return of({ data: [], total: 0 });
          return this.searchFn(query, FETCH_LIMIT, 0);
        }),
        tap((res) => {
          this.totalResults = res.total;
          this.matchedItems = res.data.filter((item) => item[this.searchKeyword]);
          this.matchedItemsChanged.emit(this.matchedItems);
        }),
        catchError((err) => {
          return of(err);
        }),
      )
      .subscribe(() => (this.searching = false));

    this.loadNextPage$
      .pipe(
        untilDestroyed(this),
        filter(() => !this.searching && this.matchedItems.length < this.totalResults),
        switchMap(() => {
          const query = this.searchQueryFormControl.value;
          return this.searchFn(query, FETCH_LIMIT, this.matchedItems.length);
        }),
        tap((res) => {
          this.totalResults = res.total;
          this.matchedItems = [...this.matchedItems, ...res.data];
          this.matchedItemsChanged.emit(this.matchedItems);
        }),
        catchError((err) => {
          this.toastrService.displayServerErrors(err);
          return of(err);
        }),
      )
      .subscribe();
  }
}
