import { Component, Input, ViewChild, inject } from '@angular/core';
import { TableComponent } from '@core/components/table/table.component';
import { Company } from '@core/models/company';
import { type TableConfig } from '@core/models/table';
import { ModalService } from '@core/services/modal.service';
import { type UserPermissions } from '@core/services/permission.service';
import { StringHelpersService } from '@core/services/string-helpers.service';
import { UserService } from '@core/services/user.service';
import { TaskHistoryModalComponent } from '@clover/tasks-history/task-history-modal/task-history-modal.component';
import { type TaskSearchModel, TaskStatus } from '@clover/tasks/models/task';
import { percentageFormatter } from '@core/helpers/nz-progress-formatters';
import { DateFormatDistancePipe, DateFormatPipe } from '@core/pipes/date-format.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { XMoreComponent } from '@core/components/x-more/x-more.component';
import { XMoreUsersComponent } from '@core/components/x-more-users/x-more-users.component';
import { UserLogoComponent } from '@core/components/user-logo/user-logo.component';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { NzProgressComponent } from 'ng-zorro-antd/progress';
import { TableComponent as TableComponent_1 } from '@clover/core/components/table/table.component';

@Component({
  selector: 'app-company-task-history',
  templateUrl: './company-task-history.component.html',
  styleUrls: ['./company-task-history.component.scss'],
  standalone: true,
  imports: [
    TableComponent_1,
    NzProgressComponent,
    FocusableDirective,
    UserLogoComponent,
    XMoreUsersComponent,
    XMoreComponent,
    TranslateModule,
    DateFormatPipe,
    DateFormatDistancePipe,
  ],
})
export class CompanyTaskHistoryComponent {
  private readonly userService = inject(UserService);
  private readonly modalService = inject(ModalService);
  public readonly stringHelpers = inject(StringHelpersService);
  @Input() company: Company;
  @Input() isMyCompany: boolean;
  @ViewChild('tasksTable') tasksTable: TableComponent;

  public isLoading = false;

  public tableConfig: TableConfig;
  public data: TaskSearchModel[] = [];
  protected readonly percentageFormatter = percentageFormatter;
  private readonly progressColors = {
    bad: '#F22206',
    medium: '#F2994A',
    good: '#27AE60',
  };

  public get TaskStatus() {
    return TaskStatus;
  }

  public get permissions(): UserPermissions {
    return this.userService.permissions;
  }

  public ngOnInit(): void {
    this.tableConfig = {
      loadUrl: this.isMyCompany ? `api/receivedCompanyTasks/search` : `api/companies/${this.company.id}/tasks/search`,
      loadLimit: 10,
      defaultSort: 'createdAt',
      defaultSortDirection: 'desc',
      filters: [
        {
          name: 'taskType',
          queryParam: 'taskType',
          defaultValue: 'Incomplete',
          options: [
            {
              label: 'tasksPage.taskTypes.all',
              value: 'All',
            },
            {
              label: 'tasksPage.taskTypes.completed',
              value: 'Completed',
            },
            {
              label: 'tasksPage.taskTypes.incomplete',
              value: 'Incomplete',
            },
            {
              label: 'tasksPage.taskTypes.closed',
              value: 'Closed',
            },
          ],
        },
      ],
      columns: [
        {
          name: 'name',
          label: 'tasksPage.columns.task',
          sortable: true,
          minWidth: '200px',
          maxWidth: '3.5fr',
        },
        {
          name: 'assignedTo',
          label: 'tasksPage.columns.assignedTo',
          sortable: false,
          minWidth: '150px',
          maxWidth: '2.5fr',
        },
        {
          name: 'createdAt',
          label: 'tasksPage.columns.assigned',
          sortable: true,
          minWidth: '150px',
          maxWidth: '1.5fr',
        },
      ],
      buttons: [
        /*         {
          label: 'tasksPage.buttons.newTask',
          class: 'btn btn-primary submit-btn',
          onClick: this.addNewTask.bind(this)
        } */
      ],
    };
  }

  public addNewTask(): void {}

  public openTaskHistoryModal(task: TaskSearchModel): void {
    this.modalService.open({
      content: TaskHistoryModalComponent,
      inputs: {
        task,
      },
    });
  }

  public color(percent: number): string {
    if (percent > 70) {
      return this.progressColors.good;
    }

    if (percent > 35) {
      return this.progressColors.medium;
    }

    return this.progressColors.bad;
  }
}
