import { Component, Input, type SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Product } from '@clover/products/models/product';
import { v4 as uuidv4 } from 'uuid';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@Component({
  selector: 'product-logo',
  templateUrl: './product-logo.component.html',
  styleUrls: ['./product-logo.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [AssetSrcDirective],
})
export class ProductLogoComponent {
  @Input() product: Product;

  public logoUrl: string;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.product) {
      this.logoUrl = this.getLogoUrl();
    }
  }

  public getLogoUrl(): string {
    const logoUrl = this.product.marketingLogoUrl || this.product.logoUrl;
    if (!logoUrl) {
      return null;
    }

    if (logoUrl.includes('http')) {
      return `${logoUrl}?v=${uuidv4()}`;
    } else {
      return logoUrl;
    }
  }
}
