import { Component, ViewChild, inject } from '@angular/core';
import { InvitationsComponent } from '../components/invitations/invitations.component';
import { Router } from '@angular/router';
import { UserService } from '@core/services/user.service';

@Component({
  selector: 'app-network-page',
  templateUrl: './network-page.component.html',
  styleUrls: ['./network-page.component.scss'],
})
export class NetworkPageComponent {
  private readonly router = inject(Router);
  private readonly userService = inject(UserService);
  @ViewChild('invitationsReceived') invitationsReceived: InvitationsComponent;
  public activeTab: string;
  public showInvitations = true;
  public showReceivedInvitations = true;
  public showCompanyLists = true;

  public tabs = {
    connections: 'Connections',
    invitationsSent: 'InvitationsSent',
    invitationsReceived: 'InvitationsReceived',
    companyLists: 'CompanyLists',
  };

  public ngOnInit(): void {
    this.showInvitations = this.userService.permissions.CompanyInvitations_View;
    this.showReceivedInvitations = this.userService.userProfile.isAbleToAcceptInvitations;
    this.showCompanyLists = this.userService.permissions.CompanyList_View;

    if (this.router.url.includes('connections')) {
      this.activeTab = this.tabs.connections;
      return;
    }

    if (this.router.url.includes('invitationsSent')) {
      if (this.showInvitations) {
        this.activeTab = this.tabs.invitationsSent;
      } else {
        this.router.navigate(['/network-v2/accounts']);
      }
    }

    if (this.router.url.includes('invitationsReceived')) {
      if (this.showInvitations) {
        this.activeTab = this.tabs.invitationsReceived;
      } else {
        this.router.navigate(['/network-v2/accounts']);
      }
    }

    if (this.router.url.includes('companyLists')) {
      if (this.showCompanyLists) {
        this.activeTab = this.tabs.companyLists;
      } else {
        this.router.navigate(['/network-v2/accounts']);
      }
    }
  }

  public get hasInvitations(): boolean {
    if (!this.invitationsReceived) {
      return false;
    }
    return this.invitationsReceived.data.length !== 0;
  }
}
