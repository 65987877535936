import { Pipe, type PipeTransform, inject } from '@angular/core';
import { LocalizationService } from '../services/localization.service';

@Pipe({
  name: 'numberFormat',
  standalone: true,
})
export class NumberFormatPipe implements PipeTransform {
  private readonly localizationService = inject(LocalizationService);

  public transform(value: number | string): string {
    return this.localizationService.localizeNumber(+value);
  }
}
