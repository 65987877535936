import { Component, Input, ViewEncapsulation, inject } from '@angular/core';
import { type TableConfig } from '@core/models/table';
import { StringHelpersService } from '@core/services/string-helpers.service';
import { TableComponent } from '../../../core/components/table/table.component';

@Component({
  selector: 'company-relationships',
  templateUrl: './company-relationships.component.html',
  styleUrls: ['./company-relationships.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [TableComponent],
})
export class CompanyRelationshipsComponent {
  private readonly stringHelpers = inject(StringHelpersService);
  @Input() data: any[] = [];

  public tableConfig: TableConfig = {
    columns: [
      {
        name: 'name',
        label: 'common.strings.name',
        sortable: true,
        minWidth: '200px',
        maxWidth: '3fr',
        manualSortValueFunc: (company) => {
          return company.name;
        },
      },
      {
        name: 'type',
        label: 'companyRelationships.type',
        sortable: true,
        minWidth: '150px',
        maxWidth: '2fr',
        manualSortValueFunc: (company) => {
          return company.relationshipType;
        },
      },
      {
        name: 'address',
        label: 'common.strings.address',
        sortable: true,
        minWidth: '200px',
        maxWidth: '3fr',
        manualSortValueFunc: (company) => {
          return this.getAddressString(company);
        },
      },
    ],
    hideControls: true,
  };

  constructor() {
    this.getAddressString = this.getAddressString.bind(this);
  }

  public getAddressString(company: any): string {
    return this.stringHelpers.getAddressString(company.headquartersLocation.address);
  }
}
