import { inject, Injectable, signal } from '@angular/core';
import { UnreadCountersService } from '@clover/conversations-v4/workspaces/state/unread-counters/unread-counters.service';
import { type ConversationMessage } from '@clover/conversations/models/message';
import {
  ConversationUpdateEvent,
  NewConversationEvent,
  NewConversationEventType,
  NewMessagePublishedEvent,
  type ConversationReadStateChangedEvent,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import {
  AddNewMessage,
  HandleConversationUpdate,
} from '@conversations/conversation/state/conversation/conversation.actions';
import { LoadLabel } from '@conversations/workspaces/state/labels/labels.actions';
import { ExternalAccountStatus } from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { PatchExternalAccount } from '@conversations/workspaces/state/workspaces/workspaces.actions';
import * as signalR from '@microsoft/signalr';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { type AppNotification } from '../models/notification';
import { ConfigService } from './config.service';
import { HttpService } from './http.service';
import { LocalStorageService } from './persistance.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  public openConnections: signalR.HubConnection[] = [];
  public conversationMessages$ = new Subject<ConversationMessage>();
  public conversationMessagesRead$ = new Subject<{
    conversationId: number;
    numberOfMessages: number;
  }>();
  public campaignPublished$ = new Subject<any>();
  public esignStarted$ = new Subject<any>();
  public notifications$ = new Subject<AppNotification>();
  public userJobs$ = new Subject<any>();
  public isOpen = false;

  readonly totalUnreadMessages = signal(0);
  readonly totalIncompleteTasks = signal(0);

  private readonly lsService = inject(LocalStorageService);
  private readonly userService = inject(UserService);
  private readonly unreadCountersService = inject(UnreadCountersService);
  private readonly httpService = inject(HttpService);
  private readonly store = inject(Store);

  public initConnections(): void {
    this.isOpen = true;

    if (this.openConnections.length > 0) {
      this.closeConnections();
    }

    this.openConnections = [];
    this.initNotificationsConnection();
    this.initUserConnection();
    this.initWorkspaceConnection();
    this.initUserJobConnection();

    if (this.userService.permissions?.Campaign_Publish || this.userService.permissions.Task_Respond) {
      this.initTasksConnection();
    }

    if (this.userService.permissions?.Conversation_Read) {
      this.initConversationsConnection();
    }
  }

  public closeConnections(): void {
    this.isOpen = false;
    this.openConnections.forEach((conn) => {
      conn.stop();
    });
    this.openConnections = [];
  }

  initWorkspaceConnection(): void {
    const connection = this.getHubConnection('workspaces');

    connection.on(
      'ExternalAccountStatusChanged',
      (event: { externalAccountId: number; newStatus: ExternalAccountStatus; workspaceId: number }) => {
        this.store.dispatch(
          new PatchExternalAccount(event.externalAccountId, {
            status: event.newStatus,
          }),
        );
      },
    );

    connection.on('NewConversationEvent', (event: NewConversationEvent) => {
      switch (event.eventType) {
        case NewConversationEventType.NewMessage:
          this.store.dispatch(new AddNewMessage(event.conversationId, event.messageId, undefined));
          break;
      }
    });

    connection.on('ConversationUpdated', ({ conversationId }: ConversationUpdateEvent) => {
      this.store.dispatch(new HandleConversationUpdate(conversationId));
    });

    connection.on(
      'ConversationReadStateChanged',
      ({ conversationId, workspaceId }: ConversationReadStateChangedEvent) => {
        this.store.dispatch(new HandleConversationUpdate(conversationId));
        this.unreadCountersService.updateWorkspaceUnreadCounters(workspaceId);
      },
    );

    connection.on('NewMessagePublished', (event: NewMessagePublishedEvent) => {
      this.store.dispatch(new AddNewMessage(event.conversationId, event.messageId, event.traceId));
    });

    connection.on('NewTagCreated', (event: { id: number; workspaceId: number }) => {
      this.store.dispatch(new LoadLabel(event.workspaceId, event.id));
    });

    this.setOnCloseMethod(connection, () => this.initWorkspaceConnection());
    this.startConnection(connection);
  }

  public initUserConnection(): void {
    const connection = this.getHubConnection('users');

    connection.on('UserInactivated', (msg) => {
      console.log(msg);
      this.userService.logout();
    });

    connection.on('UserPermissionsUpdated', (msg) => {
      console.log(msg);
      this.userService.refreshAuthToken();
    });

    this.setOnCloseMethod(connection, () => {
      this.initUserConnection();
    });
    this.startConnection(connection);
  }

  public initTasksConnection(): void {
    const connection = this.getHubConnection('tasks');

    if (this.userService.permissions?.Task_ViewAssigned) {
      this.reloadTasksCount();

      connection.on('TasksCountChanged', (msg) => {
        this.totalIncompleteTasks.update((val) => val + msg.countDifference);
      });
    }

    if (this.userService.permissions?.Campaign_Publish) {
      connection.on('CampaignPublished', (msg) => {
        this.campaignPublished$.next(msg);
      });
    }

    if (this.userService.permissions?.Task_Respond) {
      connection.on('SignatureRequestReady', (msg) => {
        this.esignStarted$.next(msg);
      });
    }

    this.setOnCloseMethod(connection, () => {
      this.initTasksConnection();
    });
    this.startConnection(connection);
  }

  public reloadTasksCount(): void {
    this.httpService
      .get(
        `/api/receivedCompanyTasks/search?AssignedToUserId=${this.userService.profile$.value.id}&offset=0&limit=1&taskType=Incomplete`,
      )
      .then((res) => {
        this.totalIncompleteTasks.set(res.total);
      });
  }

  public initNotificationsConnection(): void {
    const connection = this.getHubConnection('userNotifications');

    connection.on('ReceiveMessage', (msg) => {
      this.notifications$.next(msg);
    });

    this.setOnCloseMethod(connection, () => {
      this.initNotificationsConnection();
    });
    this.startConnection(connection);
  }

  public initConversationsConnection(): void {
    const connection = this.getHubConnection('conversations');

    this.httpService.get('api/conversations/unreadCounters').then((res) => {
      this.totalUnreadMessages.set(res.total);
    });

    connection.on('ReceiveMessage', (msg) => {
      this.conversationMessages$.next(msg);
      this.totalUnreadMessages.update((val) => val + 1);
    });

    this.setOnCloseMethod(connection, () => {
      this.initConversationsConnection();
    });
    this.startConnection(connection);
  }

  public initUserJobConnection(): void {
    const connection = this.getHubConnection('userJobs');

    connection.on('ReceiveMessage', (msg) => {
      this.userJobs$.next(msg);
    });

    this.setOnCloseMethod(connection, () => {
      this.initUserJobConnection();
    });
    this.startConnection(connection);
  }

  private startConnection(connection: signalR.HubConnection) {
    connection.start();
    this.openConnections.push(connection);
  }

  private getHubConnection(hubPath: string) {
    return new signalR.HubConnectionBuilder()
      .withUrl(`${ConfigService.settings.apiUrl}/hubs/${hubPath}`, {
        accessTokenFactory: () => this.lsService.getAuthData().token,
      })
      .build();
  }

  private setOnCloseMethod(connection: signalR.HubConnection, initMethod: () => void) {
    connection.onclose(() => {
      if (!this.isOpen) {
        return;
      }

      this.openConnections = this.openConnections.filter((conn) => conn !== connection);
      setTimeout(() => {
        initMethod();
      }, 1000);
    });
  }
}
