import { Component, Input, ViewEncapsulation, inject } from '@angular/core';
import { type TableConfig } from '@core/models/table';
import { NetworkService } from '../../network.service';
import { UserService } from '@core/services/user.service';
import { type Invitation } from '../../models/invitation';
import { ModalService } from '@core/services/modal.service';
import { DeclineInvitationModalComponent } from '../../modals/decline-invitation-modal/decline-invitation-modal.component';
import { TrustScores } from '../../models/trust-scores';
import { type CompanySearchModel } from '@core/models/company';
import { StringHelpersService } from '@core/services/string-helpers.service';
import { PendingApprovalModalComponent } from '../../modals/pending-approval-modal/pending-approval-modal.component';
import { SignalrService } from '@core/services/signalr.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, map } from 'rxjs/operators';
import { DateFormatDistancePipe } from '../../../core/pipes/date-format.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { XMoreComponent } from '../../../core/components/x-more/x-more.component';
import { RouterLink } from '@angular/router';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { CompanyLogoComponent } from '../../../core/components/company-logo/company-logo.component';
import { TableComponent } from '../../../core/components/table/table.component';

@UntilDestroy()
@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TableComponent,
    CompanyLogoComponent,
    FocusableDirective,
    RouterLink,
    XMoreComponent,
    ButtonLoadingDirective,
    TranslateModule,
    DateFormatDistancePipe,
  ],
})
export class InvitationsComponent {
  private readonly networkService = inject(NetworkService);
  private readonly userService = inject(UserService);
  private readonly modalService = inject(ModalService);
  private readonly stringHelpers = inject(StringHelpersService);
  private readonly signalR = inject(SignalrService);
  @Input() invitationType: 'sent' | 'received' = 'received';

  public isLoading = false;
  public acceptingInvitationId: number;

  public tableConfig: TableConfig;

  public data: Invitation[] = [];

  public get isImpersonating(): boolean {
    return this.userService.isImpersonating;
  }

  public ngOnInit(): void {
    this.tableConfig = {
      loadUrl: `api/companies/my/invitations/${this.invitationType}/search`,
      loadLimit: 20,
      defaultSort: 'created_at',
      defaultSortDirection: 'desc',
      filters: [
        {
          name: 'invitationType',
          queryParam: 'Status',
          defaultValue: 'Open',
          options: [
            {
              label: 'invitations.invitationTypes.all',
              value: '',
            },
            {
              label: 'invitations.invitationTypes.open',
              value: 'Open',
            },
            {
              label: 'invitations.invitationTypes.accepted',
              value: 'Accepted',
            },
            {
              label: 'invitations.invitationTypes.rejected',
              value: 'Declined',
            },
          ],
        },
      ],
      columns: [
        {
          name: 'name',
          label: 'invitations.columns.buyerCompany',
          sortable: true,
          minWidth: '150px',
          maxWidth: '2fr',
        },
        {
          name: 'created_at',
          label: 'invitations.columns.invitationDelivered',
          sortable: true,
          minWidth: '150px',
          maxWidth: '1fr',
        },
        {
          name: 'actions',
          label: 'common.labels.action',
          sortable: false,
          minWidth: this.invitationType === 'sent' ? '60px' : '100px',
          maxWidth: this.invitationType === 'sent' ? '60px' : '200px',
        },
      ],
    };
  }

  public getScoreImg(scoreValue: number): string {
    const score = scoreValue < 50 ? TrustScores[1] : TrustScores[0];
    return score ? score.logoUrl : '';
  }

  public accept(invitation: Invitation): void {
    this.isLoading = true;
    this.acceptingInvitationId = invitation.id;
    this.networkService
      .acceptInvitation({ invitationId: invitation.id })
      .pipe(
        untilDestroyed(this),
        map(() => {
          this.data = this.data.filter((inv) => inv.id !== invitation.id);
          this.signalR.reloadTasksCount();
        }),
        finalize(() => {
          this.isLoading = false;
          this.acceptingInvitationId = null;
        }),
      )
      .subscribe();
  }

  public decline(invitation: Invitation): void {
    this.modalService
      .open({
        content: DeclineInvitationModalComponent,
        inputs: {
          invitation,
        },
      })
      .result.then(() => {
        this.data = this.data.filter((inv) => inv.id !== invitation.id);
      });
  }

  public getCompanyDetailsString(company: CompanySearchModel): string {
    return this.stringHelpers.getAddressString(company?.address);
  }

  public getCompany(invitation: Invitation): CompanySearchModel {
    return this.invitationType === 'sent' ? invitation.requestTo : invitation.requestFrom;
  }

  public sendReminder(invitation: Invitation): void {}

  public showPendingApprovalModal(): void {
    this.modalService.open({
      content: PendingApprovalModalComponent,
      options: {
        size: 'sm',
      },
    });
  }
}
