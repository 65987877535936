import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';

@Component({
  selector: 'app-confirm-location-delete-modal',
  templateUrl: './confirm-location-delete-modal.component.html',
  styleUrls: ['./confirm-location-delete-modal.component.scss'],
  standalone: true,
  imports: [ButtonLoadingDirective, TranslateModule],
})
export class ConfirmLocationDeleteModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
  public isLoading = false;
}
