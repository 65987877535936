import { Injectable, inject } from '@angular/core';
import { type Address } from '../models/address';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  private readonly http = inject(HttpService);

  public async lookupAddress(address: Address): Promise<any> {
    return await this.http
      .post(`api/geolocation/address/lookup`, {
        streetAddressLine1: address.streetAddressLine1,
        streetAddressLine2: address.streetAddressLine2,
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
        country: address.country,
      })
      .then((response) => {
        if (!response) {
          return null;
        }

        const data = response;
        return {
          streetAddressLine1: data.streetAddressLine1,
          streetAddressLine2: data.streetAddressLine2,
          city: data.city,
          state: data.state,
          zipCode: data.zipCode,
          country: data.country,
          metadata: data.metadata,
        };
      });
  }

  public areAddressesEqual(adr1: Address, adr2: Address): boolean {
    return (
      (adr1.streetAddressLine1 || '').toLowerCase() === (adr2.streetAddressLine1 || '').toLowerCase() &&
      (adr1.streetAddressLine2 || '').toLowerCase() === (adr2.streetAddressLine2 || '').toLowerCase() &&
      (adr1.city || '').toLowerCase() === (adr2.city || '').toLowerCase() &&
      adr1.state === adr2.state &&
      adr1.zipCode === adr2.zipCode &&
      adr1.country === adr2.country
    );
  }
}
