import { Component, Input, inject, output } from '@angular/core';
import { type SelectOption } from '@core/components/select/select.component';
import { Company, type CompanyData, CompanyLicenseTier, ConnectionStatus } from '@core/models/company';
import { type CompanyLocation } from '@core/models/location';
import { UserRole } from '@core/models/user';
import { ModalService } from '@core/services/modal.service';
import { type UserPermissions } from '@core/services/permission.service';
import { ToastrService } from '@core/services/toastr.service';
import { UserService } from '@core/services/user.service';
import { type CustomFieldsSchema } from '@clover/custom-forms/models/custom-fields-schema';
import { AddLocationModalComponent } from '../modals/add-location-modal/add-location-modal.component';
import { type CompanyFile } from '../models/company-file';
import { NetworkService } from '../network.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { forkJoin, type Observable, of } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { LoaderComponent } from '../../core/components/loader/loader.component';
import { CompanyFilesComponent } from '../components/company-files/company-files.component';
import { FormsModule } from '@angular/forms';
import { SelectComponent } from '../../core/components/select/select.component';
import { CompanyLocationsComponent } from '../components/company-locations/company-locations.component';
import { CompanyContactsComponent } from '../components/company-contacts/company-contacts.component';
import { CompanyCustomInfoComponent } from '../components/company-custom-info/company-custom-info.component';
import { CompanyRelationshipsComponent } from '../components/company-relationships/company-relationships.component';
import { CompanyGeneralInfoComponent } from '../components/company-general-info/company-general-info.component';
import { TooltipComponent } from '../../core/components/tooltip/tooltip.component';

@UntilDestroy()
@Component({
  selector: 'app-company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.scss'],
  standalone: true,
  imports: [
    TooltipComponent,
    CompanyGeneralInfoComponent,
    CompanyRelationshipsComponent,
    CompanyCustomInfoComponent,
    CompanyContactsComponent,
    CompanyLocationsComponent,
    SelectComponent,
    FormsModule,
    CompanyFilesComponent,
    LoaderComponent,
    AssetSrcDirective,
    TranslateModule,
  ],
})
export class CompanyInformationComponent {
  private readonly networkService = inject(NetworkService);
  private readonly modalService = inject(ModalService);
  public readonly userService = inject(UserService);
  private readonly toastr = inject(ToastrService);
  @Input() company: Company;
  companyChange = output<Company>();
  @Input() isMyCompany: boolean;

  public isLoading = false;

  public sectorsMaxLength = 2;
  public categoriesMaxLength = 3;

  public showPrivateData = false;
  public showConfidentialData = false;
  public showProprietaryData = false;

  public proprietaryFiles: CompanyFile[] = [];
  public connectedCompanies: SelectOption[] = [];

  public selectedCompanyForCustomInfo: SelectOption;
  public selectedCompanySections: CompanyData[] = [];

  public hasSharedFiles: boolean;
  public isLoadingCustomInfo = false;

  public customFieldsSchema: CustomFieldsSchema;

  constructor() {
    this.internalFieldsSaveFunc = this.internalFieldsSaveFunc.bind(this);
    this.confidentialFieldsSaveFunc = this.confidentialFieldsSaveFunc.bind(this);
  }

  public get permissions(): UserPermissions {
    return this.userService.permissions;
  }

  public get isIndividualCompany(): boolean {
    return this.userService.userCompany?.licenseTier === CompanyLicenseTier.Individual;
  }

  public get isAdmin(): boolean {
    return this.userService.userProfile.roles.includes(UserRole.systemAdministrator);
  }

  public get isDataHidden(): boolean {
    return !this.isMyCompany && this.company.connectionStatus !== ConnectionStatus.Connected && !this.isAdmin;
  }

  public get customInfoSections(): CompanyData[] {
    return this.isMyCompany ? this.selectedCompanySections : this.company.proprietaryData.sections;
  }

  public ngOnInit(): void {
    this.showPrivateData =
      this.permissions.AllCompanies_ViewCompanyProfile ||
      this.isMyCompany ||
      this.company.connectionStatus === ConnectionStatus.Connected;

    this.showConfidentialData =
      this.permissions.AllCompanies_ViewCompanyProfile ||
      (this.isMyCompany && this.permissions.Company_ConfidentialInfo_View) ||
      (this.company.connectionStatus === ConnectionStatus.Connected &&
        this.permissions.ConnectedCompany_ConfidentialInfo_View);

    this.showProprietaryData =
      this.isMyCompany || this.company.connectionStatus === ConnectionStatus.Connected || this.isAdmin;

    if (this.isMyCompany) {
      this.loadConnectedCompanies();
    }

    if (!this.isMyCompany) {
      this.loadProprietaryFiles();
    }

    this.networkService
      .getCustomFieldsSchema()
      .pipe(untilDestroyed(this))
      .subscribe((res) => (this.customFieldsSchema = res));
  }

  public onGeneralInfoChanged(companyData: Company): void {
    this.companyChange.emit(companyData);
  }

  public confidentialFieldsSaveFunc(data: Record<number, string>): Observable<any> {
    this.isLoading = true;
    this.company.confidentialData.sections.forEach((section) => {
      section.fields.forEach((field) => (field.value = data[field.fieldId]));
    });

    return this.networkService.updateConfidentialFields(this.company.id, data).pipe(
      untilDestroyed(this),
      switchMap(() =>
        this.networkService.getCompany(this.company.id).pipe(
          map((res) => {
            this.companyChange.emit(res);
          }),
          catchError((err) => {
            this.toastr.displayServerErrors(err);
            return of(err);
          }),
          finalize(() => (this.isLoading = false)),
        ),
      ),
    );
  }

  public internalFieldsSaveFunc(data: Record<number, any>): Observable<any> {
    this.isLoading = true;

    return this.networkService.updateInternalFields(this.company.id, this.userService.userProfile.companyId, data).pipe(
      untilDestroyed(this),
      switchMap(() =>
        this.networkService.getCompany(this.company.id).pipe(
          map((res) => {
            this.companyChange.emit(res);
          }),
          catchError((err) => {
            this.toastr.displayServerErrors(err);
            return of(err);
          }),
          finalize(() => (this.isLoading = false)),
        ),
      ),
    );
  }

  public onSharedCompanyChanged(company: SelectOption): void {
    this.isLoadingCustomInfo = true;
    forkJoin([this.loadProprietaryFiles(), this.loadCustomInfo(company.value)])
      .pipe(finalize(() => (this.isLoadingCustomInfo = false)))
      .subscribe();
  }

  public onLocationDeleted({ deletedLocation, newContactLocation }): void {
    const newData = { ...this.company };
    newData.privateData.locations = this.company.privateData.locations.filter((loc) => loc.id !== deletedLocation.id);
    newData.privateData.contacts = this.company.privateData.contacts.map((contact) => {
      if (contact.location?.id === deletedLocation.id) {
        return {
          ...contact,
          location: newContactLocation,
        };
      }
      return contact;
    });
    this.companyChange.emit(newData);
  }

  public onLocationEdited(location: CompanyLocation): void {
    const newData = { ...this.company };
    newData.privateData.locations = this.company.privateData.locations.map((loc) =>
      loc.id !== location.id ? loc : location,
    );
    this.companyChange.emit(newData);
  }

  public addLocation(): void {
    this.modalService
      .open({
        content: AddLocationModalComponent,
        inputs: {
          companyId: this.company.id,
        },
      })
      .result.then((res) => {
        const newData = {
          ...this.company,
          privateData: {
            ...this.company.privateData,
            locations: [...this.company.privateData.locations, res],
          },
        };
        this.companyChange.emit(newData);
      })
      .catch(() => {});
  }

  private loadConnectedCompanies(): void {
    this.networkService
      .getConnectedCompanies()
      .pipe(
        untilDestroyed(this),
        map((res) => {
          if (!res?.data) {
            return;
          }

          this.connectedCompanies = res.data.map((company) => {
            return {
              value: company.id,
              label: company.name,
            };
          });
        }),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
      )
      .subscribe();
  }

  private loadCustomInfo(sharedCompanyId: number): Observable<any> {
    return this.networkService.getCustomInfoFields(this.company.id, sharedCompanyId).pipe(
      untilDestroyed(this),
      map((res) => {
        this.selectedCompanySections = res;
      }),
      catchError((err) => {
        this.toastr.displayServerErrors(err);
        return of(err);
      }),
    );
  }

  private loadProprietaryFiles(): Observable<unknown> {
    this.hasSharedFiles = false;
    return this.networkService
      .getProprietaryFiles(
        this.company.id,
        this.isMyCompany ? this.selectedCompanyForCustomInfo.value : this.userService.userProfile.companyId,
      )
      .pipe(
        untilDestroyed(this),
        map((res) => {
          if (res?.total > 0) {
            this.hasSharedFiles = true;
          }
        }),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
      );
  }
}
