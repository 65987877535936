import { Component, Input, type SimpleChanges } from '@angular/core';
import { ConnectionStatus } from '../../models/company';
import { NgStyle } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'connection-status',
  templateUrl: './connection-status.component.html',
  styleUrls: ['./connection-status.component.scss'],
  standalone: true,
  imports: [NgStyle, TranslateModule],
})
export class ConnectionStatusComponent {
  @Input() status: ConnectionStatus;

  public iconColor: string;
  public statusString: string;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.status) {
      switch (this.status) {
        case ConnectionStatus.Connected:
          this.statusString = 'connectionStatuses.connected';
          this.iconColor = '#27AE60';
          break;
        case ConnectionStatus.Invited:
          this.statusString = 'connectionStatuses.invited';
          this.iconColor = '#F2994A';
          break;
        default:
          this.statusString = 'connectionStatuses.notConnected';
          this.iconColor = '#F22206';
          break;
      }
    }
  }
}
