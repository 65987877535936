import { Component, Input, type OnInit, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyListsService } from '../../company-lists.service';
import { type CompanyList } from '../../models/company-list';
import { type SelectOption, type SelectSpecialOption } from '@core/components/select/select.component';
import { ModalService } from '@core/services/modal.service';
import { CreateListModalComponent } from '../create-list-modal/create-list-modal.component';
import { OkModalComponent } from '@core/modals/ok-modal/ok-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, finalize, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { FormsModule } from '@angular/forms';
import { SelectComponent } from '../../../core/components/select/select.component';

@UntilDestroy()
@Component({
  selector: 'app-save-companies-to-list-modal',
  templateUrl: './save-companies-to-list-modal.component.html',
  styleUrls: ['./save-companies-to-list-modal.component.scss'],
  standalone: true,
  imports: [SelectComponent, FormsModule, ButtonLoadingDirective, TranslateModule],
})
export class SaveCompaniesToListModalComponent implements OnInit {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly companyListsService = inject(CompanyListsService);
  private readonly modalService = inject(ModalService);
  @Input() companyIds: number[] = [];
  public isLoading = false;

  public selectedListId: number;
  public availableLists: SelectOption[] = [];
  public selectSpecialOptions: SelectSpecialOption[] = [
    {
      label: 'companyLists.buttons.createNewCompanyList',
      callback: this.createNewList.bind(this),
    },
  ];

  public customErrors = {
    entity_already_exists: 'companyLists.errors.listNameExists',
  };

  public ngOnInit(): void {
    this.isLoading = true;
    this.companyListsService
      .getLists()
      .pipe(
        untilDestroyed(this),
        map((res) => {
          this.availableLists = res.data.map((list) => {
            return {
              value: list.id,
              label: list.name,
            };
          });
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }

  public saveToList(): void {
    this.isLoading = true;
    this.companyListsService
      .addCompaniesToList(this.selectedListId, this.companyIds)
      .pipe(
        untilDestroyed(this),
        map(() => {
          this.activeModal.close();
          this.modalService.open({
            content: OkModalComponent,
            inputs: {
              title: this.companyIds.length === 1 ? 'companyLists.companyAdded' : 'companyLists.companiesAdded',
              text: this.companyIds.length === 1 ? 'companyLists.companyAddedMsg' : 'companyLists.companiesAddedMsg',
            },
          });
        }),
        catchError((err) => {
          this.activeModal.close();

          const error = err.error.errors[0];
          this.modalService.open({
            content: OkModalComponent,
            inputs: {
              title: 'companyLists.companyAlreadyPresent',
              text: 'companyLists.companyAlreadyPresentMsg',
              textParams: {
                companyName: error.data.companyName,
                listName: this.availableLists.find((list) => list.value === this.selectedListId).label,
              },
            },
          });

          return of(err);
        }),
      )
      .subscribe();
  }

  public createNewList(): void {
    this.isLoading = true;
    this.activeModal.close();

    this.modalService
      .open({
        content: CreateListModalComponent,
        options: {
          size: 'sm',
        },
      })
      .result.then((res: CompanyList) => {
        this.companyListsService
          .addCompaniesToList(res.id, this.companyIds)
          .pipe(
            untilDestroyed(this),
            map(() => {
              this.modalService.open({
                content: OkModalComponent,
                inputs: {
                  title: 'companyLists.listCreated',
                  text: 'companyLists.listCreatedMsg',
                },
              });
            }),
            catchError((err) => {
              this.modalService.open({
                content: OkModalComponent,
                inputs: {
                  title: 'companyLists.companiesAlreadyPresent',
                  text: 'companyLists.companiesAlreadyPresentMsg',
                },
              });

              return of(err);
            }),
          )
          .subscribe();
      })
      .catch(() => (this.isLoading = false));
  }
}
