import { Component, forwardRef, HostBinding, Input, inject } from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocationSelectorModalComponent } from '../../modals/location-selector-modal/location-selector-modal.component';
import { type CompanyLocation } from '../../models/location';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';
import { FocusableDirective } from '@core/directives/focusable.directive';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@Component({
  selector: 'location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationSelectorComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [FocusableDirective, AssetSrcDirective],
})
export class LocationSelectorComponent implements ControlValueAccessor {
  private readonly modalService = inject(ModalService);
  private readonly userService = inject(UserService);
  @Input() placeholder: string;

  @HostBinding('attr.disabled')
  public isDisabled: boolean;
  private _value: CompanyLocation;

  public get value(): CompanyLocation {
    return this._value;
  }

  public set value(val: CompanyLocation) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  public openSelector(): void {
    if (this.isDisabled) {
      return;
    }
    this.modalService
      .open({
        content: LocationSelectorModalComponent,
        inputs: {
          preSelectedLocation: this._value,
          companyId: this.userService.userProfile?.companyId,
        },
        options: {
          size: 'xl',
        },
      })
      .result.then((res) => (this.value = res))
      .catch(() => {});
  }

  public getDisplayValue(): string {
    const value = this._value;

    if (!value) {
      return '';
    }

    if (value.displayValue) {
      return value.displayValue;
    }

    return `${value.address?.streetAddressLine1} ${value.address?.city} ${value.address?.state}
     ${value.address?.zipCode}`;
  }

  public writeValue(value: CompanyLocation): void {
    this._value = value;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private onChange = (val: CompanyLocation) => {};

  private onTouched = () => {};
}
