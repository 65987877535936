import { Component, inject, Input, output, type OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AssetSrcDirective } from '@clover/core/directives/asset-src.directive';
import type { UserPermissions } from '@clover/core/services/permission.service';
import { ToastrService } from '@clover/core/services/toastr.service';
import { UserService } from '@clover/core/services/user.service';
import { InvitationSentModalComponent } from '@clover/network/modals/invitation-sent-modal/invitation-sent-modal.component';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { ConnectionStatus, type CompanySearchModel } from '@core/models/company';
import { HelpersService } from '@core/services/helpers.service';
import { ModalService } from '@core/services/modal.service';
import { StringHelpersService } from '@core/services/string-helpers.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { CompanyLogoComponent } from '../../../core/components/company-logo/company-logo.component';
import { ScrollableAreaComponent } from '../../../core/components/scrollable-area/scrollable-area.component';
import { PendingApprovalModalComponent } from '../../modals/pending-approval-modal/pending-approval-modal.component';
import { CompanySearchData, NetworkService } from '../../network.service';

const COMPANY_LOAD_LIMIT = 20;

@UntilDestroy()
@Component({
  selector: 'app-existing-companies-search',
  templateUrl: './existing-companies-search.component.html',
  styleUrls: ['./existing-companies-search.component.scss'],
  standalone: true,
  imports: [
    ScrollableAreaComponent,
    CompanyLogoComponent,
    FocusableDirective,
    RouterLink,
    TranslateModule,
    AssetSrcDirective,
  ],
})
export class ExistingCompaniesSearchComponent implements OnInit {
  private readonly helpers = inject(HelpersService);
  private readonly stringHelpers = inject(StringHelpersService);
  private readonly modalService = inject(ModalService);
  private readonly networkService = inject(NetworkService);
  private readonly userService = inject(UserService);
  private readonly toastr = inject(ToastrService);

  @Input() searchData: CompanySearchData;
  @Input() isSelecting: boolean;
  @Input() nonConnectedCompanyAction: 'default' | 'connect' = 'default';

  companySelected = output<CompanySearchModel>();

  public foundCompanies: CompanySearchModel[] = [];
  public isLoading = false;

  private totalCompanies: number = null;

  public get permissions(): UserPermissions {
    return this.userService.permissions;
  }

  public get isIndividualCompany(): boolean {
    return this.userService.userCompany.licenseTier === 'Individual';
  }

  public ngOnInit(): void {
    this.loadCompanies();
  }

  public loadCompanies(): void {
    if (this.isLoading) {
      return;
    }

    if (this.totalCompanies !== null && this.totalCompanies === this.foundCompanies.length) {
      return;
    }

    this.isLoading = true;
    this.networkService
      .searchCompanies(this.searchData, this.foundCompanies.length, COMPANY_LOAD_LIMIT)
      .pipe(
        untilDestroyed(this),
        map((res) => {
          this.foundCompanies = [...this.foundCompanies, ...res.data];
          this.totalCompanies = res.total;
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }

  public getCompanyDetailsString(company: CompanySearchModel | any): string {
    return this.stringHelpers.getCompanyDetailsString(company);
  }

  public getDescription(company: CompanySearchModel | any): string {
    return this.helpers.getCompanyDescription(company);
  }

  public selectCompany(company: any): void {
    this.companySelected.emit(company);
  }

  public showPendingApprovalModal(): void {
    this.modalService.open({
      content: PendingApprovalModalComponent,
      options: {
        size: 'sm',
      },
    });
  }

  public connect(company: CompanySearchModel): void {
    if (!this.permissions.Company_Connect) {
      this.modalService.openPremiumFeatureModal();
      return;
    }

    this.networkService
      .sendInvitation(company.id)
      .pipe(
        untilDestroyed(this),
        map(() => {
          company.connectionStatus = ConnectionStatus.Invited;
          this.modalService.open({
            content: InvitationSentModalComponent,
            inputs: {
              company,
            },
          });
        }),
        catchError((err) => {
          this.toastr.error(`The opened or accepted invitation for ${company.name} already exists.`);
          return of(err);
        }),
      )
      .subscribe();
  }
}
