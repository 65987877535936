import { Component, ElementRef, HostListener, Input, ViewEncapsulation, inject } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { NgTemplateOutlet } from '@angular/common';

export enum TooltipColorSchema {
  White = 'white',
  Black = 'black',
}

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FocusableDirective, NgTemplateOutlet],
})
export class TooltipComponent {
  private readonly element = inject(ElementRef);
  private readonly userService = inject(UserService);
  @Input() maxWidth: number = 400;
  @Input() contentTemplate: any;
  @Input() theme: TooltipColorSchema;
  @Input() topOffset = 0;
  @Input() leftOffset = 25;

  public isHovered: boolean = false;
  public isClicked: boolean = false;

  public leftPosition: string;
  public topPosition: string;
  public maxContentWidth: string;

  private tooltipElement: any;

  private isAlive: boolean = true;

  constructor() {
    this.onMouseMove = this.onMouseMove.bind(this);
    this.checkTooltipVisibility = this.checkTooltipVisibility.bind(this);
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (this.tooltipElement) {
      this.hideTooltip();
    }
    this.isHovered = true;

    this.tooltipElement = document.createElement('div');
    this.tooltipElement.classList.add('tooltip_content');

    if (this.theme === TooltipColorSchema.Black) {
      this.tooltipElement.classList.add('tooltip-black');
    }

    const tooltipContent = this.element.nativeElement.querySelector('.tooltip_content');
    this.tooltipElement.innerHTML = tooltipContent.innerHTML;
    this.tooltipElement.style.maxWidth = this.getMaxContentWidth();
    this.tooltipElement.style.visibility = 'hidden';
    setTimeout(() => {
      if (!this.tooltipElement) {
        return;
      }
      this.tooltipElement.style.top = this.getTopPosition();
      this.tooltipElement.style.left = this.getLeftPosition();
      this.tooltipElement.style.visibility = 'visible';
    }, 0);

    const parent = document.body;
    parent.appendChild(this.tooltipElement);
    document.addEventListener('mousemove', this.onMouseMove);
    this.element.nativeElement.addEventListener('wheel', this.checkTooltipVisibility);
    document.addEventListener('keydown', this.checkTooltipVisibility);

    this.userService.isAuthenticated$.pipe(takeWhile((_) => this.isAlive)).subscribe((isAuth) => {
      if (!isAuth) {
        this.hideTooltip();
      }
    });
  }

  public ngOnDestroy(): void {
    this.hideTooltip();
    document.removeEventListener('mousemove', this.onMouseMove);
    this.element.nativeElement.removeEventListener('wheel', this.checkTooltipVisibility);
    document.removeEventListener('keydown', this.checkTooltipVisibility);
    this.isAlive = false;
  }

  public getMaxContentWidth(): string {
    const parent = document.body;
    return `${parent.clientWidth > this.maxWidth ? this.maxWidth : parent.clientWidth}px`;
  }

  private checkTooltipVisibility(event: MouseEvent): void {
    setTimeout(() => {
      if (!this.tooltipElement) {
        return;
      }

      const box = this.element.nativeElement.getBoundingClientRect();
      if (event.clientY > box.top && event.clientY < box.top + box.height) {
        this.tooltipElement.style.top = this.getTopPosition();
      } else {
        this.hideTooltip();
      }
    }, 0);
  }

  private onMouseMove(event: MouseEvent): void {
    if (!this.tooltipElement) {
      return;
    }
    const isInsideElement =
      this.element.nativeElement.contains(event.target) || this.tooltipElement.contains(event.target);
    if (!isInsideElement) {
      this.hideTooltip();
    }
  }

  private hideTooltip(): void {
    if (!this.tooltipElement) {
      return;
    }
    const parent = document.body;
    parent?.removeChild(this.tooltipElement);
    this.tooltipElement = null;
    this.isHovered = false;
    document.removeEventListener('mousemove', this.onMouseMove);
    this.element.nativeElement.removeEventListener('wheel', this.checkTooltipVisibility);
  }

  private getTopPosition(): string {
    const element = this.element.nativeElement.querySelector('.tooltip_element').getBoundingClientRect();
    return element.top + this.topOffset + 'px';
  }

  private getLeftPosition(): string {
    const box = this.element.nativeElement.getBoundingClientRect();
    const parent = document.body;
    const parentBox = parent.getBoundingClientRect();

    let contentWidthOffset =
      (this.tooltipElement.clientWidth > this.maxWidth ? this.maxWidth : this.tooltipElement.clientWidth) / 2;
    const maxOffset = box.left - parentBox.left;

    if (maxOffset < contentWidthOffset) {
      contentWidthOffset = maxOffset;
    }

    return `${box.left + box.width + this.leftOffset}px`;
  }
}
