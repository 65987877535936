import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@Component({
  selector: 'great-work-modal',
  templateUrl: './great-work-modal.component.html',
  styleUrls: ['./great-work-modal.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective, TranslateModule],
})
export class GreatWorkModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
}
