import { Component, Input, ViewEncapsulation, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { type TableConfig } from '@core/models/table';
import { CompanyLocation } from '../../models/location';
import { StringHelpersService } from '../../services/string-helpers.service';
import { TableComponent } from '@core/components/table/table.component';

import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RadioComponent } from '@design/forms/radio/radio.component';

@Component({
  selector: 'location-selector-modal',
  templateUrl: './location-selector-modal.component.html',
  styleUrls: ['./location-selector-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [TableComponent, FormsModule, TranslateModule, RadioComponent],
})
export class LocationSelectorModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly stringHelpers = inject(StringHelpersService);
  @Input() preSelectedLocation: CompanyLocation;
  @Input() companyId: number;

  public selectedLocation: CompanyLocation;

  public locations: CompanyLocation[] = [];
  public tableConfig: TableConfig;

  public ngOnInit(): void {
    this.selectedLocation = this.preSelectedLocation;
    this.generateConfig();
  }

  public getAddressString(location: any): string {
    return this.stringHelpers.getAddressString(location.address);
  }

  public submitSelection(): void {
    this.activeModal.close(this.selectedLocation);
  }

  public selectLocation(location: CompanyLocation): void {
    this.selectedLocation = location;
  }

  private generateConfig(): void {
    this.tableConfig = {
      loadUrl: `api/companies/${this.companyId}/locations`,
      columns: [
        {
          name: 'select',
          label: 'common.buttons.select',
          sortable: false,
          minWidth: '70px',
          maxWidth: '70px',
        },
        {
          name: 'name',
          label: 'common.strings.name',
          sortable: true,
          minWidth: '150px',
          maxWidth: '1fr',
          manualSortValueFunc: (location) => {
            return location.name;
          },
        },
        {
          name: 'location',
          label: 'companyLocations.type',
          sortable: true,
          minWidth: '150px',
          maxWidth: '1fr',
          manualSortValueFunc: (location) => {
            return location.type.title;
          },
        },
        {
          name: 'gln',
          label: 'companyLocations.gln',
          sortable: true,
          minWidth: '150px',
          maxWidth: '1fr',
          manualSortValueFunc: (location) => {
            return location.globalLocationNumber;
          },
        },
        {
          name: 'address',
          label: 'common.strings.address',
          sortable: true,
          minWidth: '300px',
          maxWidth: '2fr',
          manualSortValueFunc: (location) => {
            return this.getAddressString(location);
          },
        },
      ],
      hideControls: true,
    };
  }
}
