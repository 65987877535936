import { Component, Input, inject } from '@angular/core';
import {
  TreeviewPickerComponent,
  type TreeviewPickerOption,
} from '@core/components/treeview-picker/treeview-picker.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { type EnumerationModel } from '@core/models/enumerationModel';
import { HelpersService } from '@core/services/helpers.service';
import { ScrollableAreaComponent } from '@core/components/scrollable-area/scrollable-area.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'sectors-selector-modal',
  templateUrl: './sectors-selector-modal.component.html',
  styleUrls: ['./sectors-selector-modal.component.scss'],
  standalone: true,
  imports: [ScrollableAreaComponent, TreeviewPickerComponent, FormsModule, TranslateModule],
})
export class SectorsSelectorModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly helpers = inject(HelpersService);
  @Input() options: TreeviewPickerOption[] = [];
  @Input() selectedSectors: EnumerationModel[] = [];

  public selectedOptions: string[] = [];

  public ngOnInit(): void {
    if (this.selectedSectors) {
      this.selectedOptions = this.selectedSectors.filter((sector) => !!sector).map((sector) => sector.key);
    }
  }

  public save(): void {
    const options = this.selectedOptions
      .map((value) => {
        const option = this.helpers.treeSearch(this.options, value);
        return option
          ? {
              key: option.value,
              title: option.label,
            }
          : null;
      })
      .filter((value) => !!value);

    this.activeModal.close(options);
  }
}
