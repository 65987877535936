import { type User } from '@core/models/user';

export interface TaskHistoryEvent {
  id?: number;
  type: TaskHistoryEventType;
  assignedTo?: {
    communicationRoles: Array<{ key: string; title: string }>;
    users: User[];
  };
  performer?: User;
  copiedFromProduct: any;
  stepName: string;
  createdAt?: string;
  sequenceStepNumber?: number;
  totalStepNumber?: number;
}

export enum TaskHistoryEventType {
  TaskStarted = 'TaskStarted',
  Assigned = 'TaskAssigned',
  Reassigned = 'TaskReassigned',
  TaskAccepted = 'TaskAccepted',
  StepStarted = 'StepStarted',
  StepSubmitted = 'StepSubmitted',
  StepRestarted = 'StepRestarted',
  TaskClosed = 'TaskClosed',
  TaskCompleted = 'TaskCompleted',
  ProductTaskCopied = 'ProductTaskCopied',
}
