import { Component, ElementRef, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { type Company, CompanyLicenseTier, ConnectionStatus } from '@core/models/company';
import { NetworkService } from '../network.service';
import { ModalService } from '@core/services/modal.service';
import { UserService } from '@core/services/user.service';
import { SaveCompaniesToListModalComponent } from '@clover/company-lists/modals/save-companies-to-list-modal/save-companies-to-list-modal.component';
import { type UserPermissions } from '@core/services/permission.service';
import { ToastrService } from '@core/services/toastr.service';
import { DokaService } from '@core/services/doka.service';
import { InvitationSentModalComponent } from '../modals/invitation-sent-modal/invitation-sent-modal.component';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, map, switchMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { CompanyFilesComponent } from '../components/company-files/company-files.component';
import { CompanyProductsComponent } from '../company-products/company-products.component';
import { CompanyTaskHistoryComponent } from '../company-task-history/company-task-history.component';
import { CompanyInformationComponent } from '../company-information/company-information.component';
import { CompanyHeaderComponent } from '../components/company-header/company-header.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ConnectionStatusComponent } from '../../core/components/connection-status/connection-status.component';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { CompanyLogoComponent } from '../../core/components/company-logo/company-logo.component';

@UntilDestroy()
@Component({
  selector: 'app-company-details-page',
  templateUrl: './company-details-page.component.html',
  styleUrls: ['./company-details-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CompanyLogoComponent,
    FocusableDirective,
    ConnectionStatusComponent,
    AssetSrcDirective,
    CompanyHeaderComponent,
    CompanyInformationComponent,
    CompanyTaskHistoryComponent,
    CompanyProductsComponent,
    CompanyFilesComponent,
    TranslateModule,
  ],
})
export class CompanyDetailsPageComponent {
  private readonly modalService = inject(ModalService);
  public readonly userService = inject(UserService);
  private readonly dokaService = inject(DokaService);
  private readonly networkService = inject(NetworkService);
  private readonly toastr = inject(ToastrService);
  private readonly route = inject(ActivatedRoute);
  @ViewChild('fileInput') fileInput: ElementRef;
  public company: Company;
  public isMyCompany = false;
  public isUploadingImage = false;

  public tabs = {
    information: 'Information',
    files: 'Files',
    taskHistory: 'TaskHistory',
    products: 'Products',
  };

  public activeTab: string = this.tabs.information;

  public get isImpersonating(): boolean {
    return this.userService.isImpersonating;
  }

  public get permissions(): UserPermissions {
    return this.userService.permissions;
  }

  public get isIndividualCompany(): boolean {
    return this.userService.userCompany?.licenseTier === CompanyLicenseTier.Individual;
  }

  public ngOnInit(): void {
    this.company = this.route.snapshot.data.company;
    this.isMyCompany = +this.userService.userProfile.companyId === this.company.id;
  }

  public onCompanyDataChanged(data: Company): void {
    this.company = data;
  }

  public saveCompanyToList(): void {
    this.modalService.open({
      content: SaveCompaniesToListModalComponent,
      inputs: {
        companyIds: [this.company.id],
      },
      options: {
        size: 'sm',
      },
    });
  }

  public certify(): void {
    if (!this.permissions.Company_Certify) {
      this.modalService.openPremiumFeatureModal();
    }
  }

  public uncertify(): void {
    if (!this.permissions.Company_Certify) {
      this.modalService.openPremiumFeatureModal();
    }
  }

  public sendInvitation(): void {
    if (!this.permissions.Company_Connect) {
      this.modalService.openPremiumFeatureModal();
      return;
    }
    this.networkService
      .sendInvitation(this.company.id)
      .pipe(
        untilDestroyed(this),
        map(() => {
          this.company.connectionStatus = ConnectionStatus.Invited;
          this.modalService.open({
            content: InvitationSentModalComponent,
            inputs: {
              company: this.company,
            },
          });
        }),
      )
      .subscribe();
  }

  public openFileSelector(): void {
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }

  public onLogoSelected(event: any): void {
    const file = event.target.files[0];
    this.dokaService
      .openDoka(file)
      .then((res) => {
        this.isUploadingImage = true;
        this.company.logoUrl = res.base64;
        combineLatest([this.dokaService.uploadImage(file), this.dokaService.uploadImage(res.file)])
          .pipe(
            switchMap((fileResponses) => {
              const newImageData = {
                fullSizeLogoImageId: fileResponses[0].fileName,
                logoImageId: fileResponses[1].fileName,
                logoSettings: JSON.stringify(res.dokaConfig),
              };

              return this.networkService.updateCompanyLogo(this.company.id, newImageData).pipe(
                map((updatedCompanyData) => {
                  this.company = updatedCompanyData;
                }),
                finalize(() => (this.isUploadingImage = false)),
              );
            }),
          )
          .subscribe();
      })
      .catch(() => {});
  }
}
