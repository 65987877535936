import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskHistoryModalComponent } from './task-history-modal/task-history-modal.component';
import { TasksHistoryService } from './tasks-history.service';
import { TranslateModule } from '@ngx-translate/core';

import { RouterLink } from '@angular/router';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { LoaderComponent } from '@core/components/loader/loader.component';
import { ScrollableAreaComponent } from '@core/components/scrollable-area/scrollable-area.component';
import { UserLogoComponent } from '@core/components/user-logo/user-logo.component';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { DateFormatPipe, DateFormatRelativePipe } from '@core/pipes/date-format.pipe';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    RouterLink,
    NzProgressModule,
    LoaderComponent,
    ScrollableAreaComponent,
    UserLogoComponent,
    FocusableDirective,
    AssetSrcDirective,
    DateFormatRelativePipe,
    DateFormatPipe,
    TaskHistoryModalComponent,
  ],
  providers: [TasksHistoryService],
  exports: [TaskHistoryModalComponent],
})
export class TasksHistoryModule {}
