import { Component, Input, ViewEncapsulation, inject } from '@angular/core';
import { type TableConfig } from '@core/models/table';
import { type CompanyContact } from '../../models/contact';
import { UserService } from '@core/services/user.service';
import { type UserPermissions } from '@core/services/permission.service';
import { Router } from '@angular/router';
import { UserStatus } from '@core/models/user';
import { ModalService } from '@core/services/modal.service';
import { OkModalComponent } from '@core/modals/ok-modal/ok-modal.component';
import { NetworkService } from '../../network.service';
import { UserActivityService } from '@core/services/user-activity.service';
import { ToastrService } from '@core/services/toastr.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { UserLogoComponent } from '../../../core/components/user-logo/user-logo.component';
import { TableComponent } from '../../../core/components/table/table.component';

const VISIBLE_CONTACTS_NUMBER = 15;

@UntilDestroy()
@Component({
  selector: 'company-contacts',
  templateUrl: './company-contacts.component.html',
  styleUrls: ['./company-contacts.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TableComponent,
    UserLogoComponent,
    NgbDropdown,
    FocusableDirective,
    NgbDropdownToggle,
    NgbDropdownMenu,
    TranslateModule,
  ],
})
export class CompanyContactsComponent {
  private readonly userService = inject(UserService);
  private readonly router = inject(Router);
  private readonly modalService = inject(ModalService);
  private readonly networkService = inject(NetworkService);
  private readonly userActivityService = inject(UserActivityService);
  private readonly toastr = inject(ToastrService);
  @Input() isMyCompany = false;
  @Input() data: CompanyContact[] = [];

  public contacts: CompanyContact[] = [];
  public isFullDataVisible = false;

  public tableConfig: TableConfig = {
    columns: [
      {
        name: 'name',
        label: 'common.strings.name',
        sortable: true,
        minWidth: '200px',
        maxWidth: '1.5fr',
        manualSortValueFunc: (contact) => {
          return `${contact.firstName} ${contact.lastName}`;
        },
      },
      {
        name: 'location',
        label: 'companyContacts.location',
        sortable: true,
        minWidth: '150px',
        maxWidth: '1fr',
        manualSortValueFunc: (contact) => {
          return contact.location.name;
        },
      },
      {
        name: 'email',
        label: 'common.strings.email',
        sortable: true,
        minWidth: '200px',
        maxWidth: '1.5fr',
        manualSortValueFunc: (contact) => {
          return contact.workEmail;
        },
      },
    ],
    hideControls: true,
  };

  public get permissions(): UserPermissions {
    return this.userService.permissions;
  }

  public ngOnInit(): void {
    this.isFullDataVisible = this.data?.length <= VISIBLE_CONTACTS_NUMBER;
    this.contacts = this.isFullDataVisible ? [...this.data] : this.data.slice(0, 15);
  }

  public isCurrentUser(contact: CompanyContact): boolean {
    return contact.id === this.userService.userProfile.id;
  }

  public addToFavorites(contact: CompanyContact): void {
    this.changeContactFavoriteStatus(contact.id, true);
  }

  public removeFromFavorites(contact: CompanyContact): void {
    this.changeContactFavoriteStatus(contact.id, false);
  }

  public isContactFavorite(contact: CompanyContact): boolean {
    return this.userActivityService.getFavoriteStatus(contact.id);
  }

  public changeContactFavoriteStatus(userId: number, isFavorite: boolean): void {
    const method = isFavorite
      ? this.networkService.addUserToFavorites(userId)
      : this.networkService.removeUserFromFavorites(userId);
    method
      .pipe(
        untilDestroyed(this),
        map(() => {
          this.userActivityService.userData[userId].isFavorite = isFavorite;
        }),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
      )
      .subscribe();
  }

  public chat(contact: CompanyContact): void {
    if (contact.status === UserStatus.Active) {
      this.router.navigate([`conversations`], {
        queryParams: { userId: contact.id },
      });
    } else {
      this.modalService.open({
        content: OkModalComponent,
        inputs: {
          title: 'userInactiveModal.title',
          text: 'userInactiveModal.userInactiveMsg',
        },
      });
    }
  }

  public assignTask(contact: CompanyContact): void {}

  public showAll(): void {
    this.contacts = [...this.data];
    this.isFullDataVisible = true;
  }
}
