import { Component, Input, type OnInit, inject } from '@angular/core';
import { type Invitation } from '../../models/invitation';
import { NetworkService } from '../../network.service';
import { ModalService } from '@core/services/modal.service';
import { DeclineInvitationModalComponent } from '../../modals/decline-invitation-modal/decline-invitation-modal.component';
import { SignalrService } from '@core/services/signalr.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, map } from 'rxjs/operators';
import { DateFormatDistancePipe } from '../../../core/pipes/date-format.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { CompanyLogoComponent } from '../../../core/components/company-logo/company-logo.component';
import { LoaderComponent } from '../../../core/components/loader/loader.component';
import { RouterLink } from '@angular/router';
import { FocusableDirective } from '@core/directives/focusable.directive';

@UntilDestroy()
@Component({
  selector: 'invitations-widget',
  templateUrl: './invitations-widget.component.html',
  styleUrls: ['./invitations-widget.component.scss'],
  standalone: true,
  imports: [
    FocusableDirective,
    RouterLink,
    LoaderComponent,
    CompanyLogoComponent,
    ButtonLoadingDirective,
    TranslateModule,
    DateFormatDistancePipe,
  ],
})
export class InvitationsWidgetComponent implements OnInit {
  private readonly networkService = inject(NetworkService);
  private readonly modalService = inject(ModalService);
  private readonly signalR = inject(SignalrService);
  @Input() disabled = false;
  public isLoading = true;
  public loadedData: Invitation[] = [];
  public totalCount = 0;

  public ngOnInit(): void {
    this.loadData();
  }

  public accept(invitation: Invitation): void {
    this.isLoading = true;
    this.networkService
      .acceptInvitation({ invitationId: invitation.id })
      .pipe(
        map(() => {
          this.loadDataAfterOperation(invitation.id);
          this.signalR.reloadTasksCount();
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }

  public decline(invitation: Invitation): void {
    this.modalService
      .open({
        content: DeclineInvitationModalComponent,
        inputs: {
          invitation,
        },
      })
      .result.then(() => {
        this.loadDataAfterOperation(invitation.id);
      });
  }

  private loadData(): void {
    this.isLoading = true;
    this.networkService
      .getOpenInvitations(3)
      .pipe(
        untilDestroyed(this),
        map((res) => {
          this.loadedData = res.data;
          this.totalCount = res.total;
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }

  private loadDataAfterOperation(invitationId: number): void {
    this.isLoading = true;
    this.networkService
      .getOpenInvitations(4)
      .pipe(
        map((res) => {
          let filteredData = res.data.filter((invitation) => invitation.id !== invitationId);
          if (filteredData.length > 3) {
            filteredData = filteredData.slice(0, 3);
          }
          this.loadedData = filteredData;
          this.totalCount = res.total > 0 ? res.total - 1 : 0;
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }
}
