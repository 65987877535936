import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyListsService } from '../../company-lists.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, map } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';

@UntilDestroy()
@Component({
  selector: 'app-delete-list-modal',
  templateUrl: './delete-list-modal.component.html',
  styleUrls: ['./delete-list-modal.component.scss'],
  standalone: true,
  imports: [ButtonLoadingDirective, TranslateModule],
})
export class DeleteListModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly companyListsService = inject(CompanyListsService);
  @Input() listId: number;
  @Input() name: string;

  public isLoading = false;

  public customErrors = {
    entity_already_exists: 'companyLists.errors.listNameExists',
  };

  public deleteList(): void {
    this.isLoading = true;
    this.companyListsService
      .deleteList(this.listId)
      .pipe(
        untilDestroyed(this),
        map(() => {
          this.activeModal.close();
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }
}
