import { Component, inject } from '@angular/core';
import { type TableConfig } from '@core/models/table';
import { type CompanySearchModel } from '@core/models/company';
import { StringHelpersService } from '@core/services/string-helpers.service';
import { TranslateModule } from '@ngx-translate/core';
import { XMoreComponent } from '../../../core/components/x-more/x-more.component';
import { RouterLink } from '@angular/router';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { CompanyLogoComponent } from '../../../core/components/company-logo/company-logo.component';
import { TableComponent } from '../../../core/components/table/table.component';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss'],
  standalone: true,
  imports: [TableComponent, CompanyLogoComponent, FocusableDirective, RouterLink, XMoreComponent, TranslateModule],
})
export class ConnectionsComponent {
  private readonly stringHelpers = inject(StringHelpersService);
  public tableConfig: TableConfig = {
    loadUrl: 'api/companies/search',
    method: 'POST',
    loadLimit: 20,
    filters: [],
    columns: [
      {
        name: 'name',
        label: 'connections.columns.buyerCompany',
        sortable: true,
        minWidth: '300px',
        maxWidth: '6fr',
      },
    ],
  };

  public data: Array<{
    id: number;
    companyId: number;
    connectionType: string;
    associatedCompany: CompanySearchModel;
  }> = [];

  public focusGlobalSearch(): void {
    const searchInput = document.querySelector('#globalSearchInput') as HTMLElement;
    if (searchInput) {
      searchInput.focus();
    } else {
      const searchIcon = document.querySelector('.global-search_expand-btn') as HTMLElement;
      searchIcon.click();
    }
  }

  public getCompanyDetailsString(company: CompanySearchModel): string {
    return this.stringHelpers.getAddressString(company.address);
  }
}
