import { Component, Input, ViewEncapsulation, inject, output } from '@angular/core';
import { Company } from '@core/models/company';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { type SelectOption } from '@core/components/select/select.component';
import { EnumService } from '@core/services/enum.service';
import { NetworkService } from '../../network.service';
import { ToastrService } from '@core/services/toastr.service';
import { type EnumerationModel } from '@core/models/enumerationModel';
import { WebsiteValidator } from '@core/validators/website.validator';
import { catchError, finalize, map } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { NgxMaskDirective } from 'ngx-mask';
import { SelectComponent } from '../../../core/components/select/select.component';
import { ProductCategoriesSelectorComponent } from '../../../core/components/product-categories-selector/product-categories-selector.component';
import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { SectorsSelectorComponent } from '../../../core/components/sectors-selector/sectors-selector.component';
import { NgClass } from '@angular/common';
import { CompanyInfoComponent } from '../../../core/components/company-info/company-info.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

@UntilDestroy()
@Component({
  selector: 'company-general-info',
  templateUrl: './company-general-info.component.html',
  styleUrls: ['./company-general-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    CompanyInfoComponent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    SectorsSelectorComponent,
    FormErrorMessagesComponent,
    ProductCategoriesSelectorComponent,
    SelectComponent,
    NgxMaskDirective,
    ButtonLoadingDirective,
    TranslateModule,
  ],
})
export class CompanyGeneralInfoComponent {
  private readonly enumService = inject(EnumService);
  private readonly networkService = inject(NetworkService);
  private readonly toastr = inject(ToastrService);
  @Input() company: Company;
  @Input() isEditable: boolean;
  @Input() disabled: boolean;
  dataChanged = output();

  public isEdit = false;
  public form: UntypedFormGroup;
  public isFormSubmitted = false;
  public isLoading = false;

  public businessTypes: SelectOption[] = [];
  public employeeNumbers: SelectOption[] = [];
  public companyTypes: SelectOption[] = [];
  public diversityStatuses: SelectOption[] = [];
  public legalTypes: SelectOption[] = [];
  public annualRevenues: SelectOption[] = [];

  public edit(): void {
    this.loadEnums();

    this.form = new UntypedFormGroup({
      websiteUrl: new UntypedFormControl(this.company.publicData?.websiteUrl, [
        Validators.required,
        WebsiteValidator(),
      ]),
      sectors: new UntypedFormControl(this.company.publicData?.subIndustries?.subIndustries, [Validators.required]),
      productCategories: new UntypedFormControl(this.company.publicData?.productCategories?.categories, [
        Validators.required,
      ]),
      businessType: new UntypedFormControl(this.company.publicData?.businessType?.key, [Validators.required]),
      numberOfEmployees: new UntypedFormControl(this.company.publicData?.numberOfEmployeesType?.key, [
        Validators.required,
      ]),
      companyType: new UntypedFormControl(this.company.publicData?.companyType?.key, [Validators.required]),
      legalType: new UntypedFormControl(this.company.publicData?.legalType?.key, []),
      tickerSymbol: new UntypedFormControl(this.company.publicData?.tickerSymbol, []),
      annualRevenue: new UntypedFormControl(this.company.publicData?.annualRevenue?.key, []),
      yearStarted: new UntypedFormControl(this.company.publicData?.yearStarted, []),
    });

    this.isEdit = true;
  }

  public cancel(): void {
    this.isEdit = false;
  }

  public save(): void {
    this.isFormSubmitted = true;

    if (this.form.invalid) {
      return;
    }

    const formValue = this.form.value;
    const fields = {
      websiteUrl: formValue.websiteUrl,
      productCategories: formValue.productCategories,
      tickerSymbol: formValue.tickerSymbol,
      subIndustries: formValue.sectors,
      businessType: this.convertOptionToModel(this.businessTypes.find((type) => type.value === formValue.businessType)),
      numberOfEmployeesType: this.convertOptionToModel(
        this.employeeNumbers.find((type) => type.value === formValue.numberOfEmployees),
      ),
      companyType: this.convertOptionToModel(this.companyTypes.find((type) => type.value === formValue.companyType)),
      legalType: this.convertOptionToModel(this.legalTypes.find((type) => type.value === formValue.legalType)),
      annualRevenue: this.convertOptionToModel(
        this.annualRevenues.find((type) => type.value === formValue.annualRevenue),
      ),
      yearStarted: formValue.yearStarted,
    };

    this.isLoading = true;
    this.networkService
      .updateCompanyProfile(this.company.id, fields)
      .pipe(
        map((res) => {
          this.isEdit = false;
          this.dataChanged.emit(res);
        }),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }

  private loadEnums(): void {
    combineLatest([
      this.enumService.getBusinessTypes(),
      this.enumService.getEmployeeNumbers(),
      this.enumService.getCompanyTypes(),
      this.enumService.getDiversityStatuses(),
      this.enumService.getLegalTypes(),
      this.enumService.getAnnualRevenues(),
    ])
      .pipe(
        untilDestroyed(this),
        map(([businessTypes, employeeNumbers, companyTypes, diversityStatuses, legalTypes, annualRevenues]) => ({
          bt: businessTypes,
          en: employeeNumbers,
          ct: companyTypes,
          ds: diversityStatuses,
          lt: legalTypes,
          ar: annualRevenues,
        })),
      )
      .subscribe((res) => {
        this.businessTypes = res.bt;
        this.employeeNumbers = res.en;
        this.companyTypes = res.ct;
        this.diversityStatuses = res.ds;
        this.legalTypes = res.lt;
        this.annualRevenues = res.ar;
      });
  }

  private convertOptionToModel(option: SelectOption): EnumerationModel {
    return option
      ? {
          key: option.value,
          title: option.label,
        }
      : null;
  }
}
